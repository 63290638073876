<template>
  <div>
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->
    <van-sticky>
      <van-nav-bar title="我的团队">
        <template #left> <van-icon name="arrow-left" /> </template>
        <!-- <template #right> 欢迎您 {{ selfData.name }} </template> -->
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <div class="cardWrap">
        <div class="cardBox" @click="$router.push('/myReport')">
          <van-icon name="manager-o" size="50" />
          <p>个人{{ $returnBuySel("报表") }}</p>
        </div>
        <div class="cardBox" @click="$router.push('/teamReport')">
          <van-icon name="friends-o" size="50" />
          <p>团队{{ $returnBuySel("报表") }}</p>
        </div>
        <div class="cardBox" @click="$router.push('/subList')">
          <van-icon name="friends-o" size="50" />
          <p>下线列表</p>
        </div>
        <b></b>
        <b></b>
        <b></b>
        <b></b>
        <b></b>
        <b></b>
        <b></b>
      </div>
    </div>
    <foot />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foot from "../../components/footer";
export default {
  components: { foot },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      "selfData",
      // ...
    ]),
  },
  methods: {},
  created() {
    this.$http.get(`/mobile/report`);
  },
};
</script>

<style lang="scss" scoped>
.cardWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .cardBox {
    width: 150px;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  b {
    width: 140px;
    height: 0;
  }
}
</style>
